import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Container = styled.section`
  width: 100%;
  text-align: center;
  padding-top: 25px;

  h3 {
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin-bottom: 26px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    font-weight: var(--font-weight-regular);
    color: #888;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 22px;
      /* huh */
    }
  }

  ul li p {
    margin-bottom: 0;
  }
`;

export default function RichText({ document }) {
  return <Container>{documentToReactComponents(document)}</Container>;
}
