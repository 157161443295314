import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Layout from '@components/layout';
import Vimeo from '@components/vimeo';
import Wrapper from '@components/wrapper';
import RichText from '@components/richtext';
import Grid from '@components/grid';
import Pagination from '@components/pagination';
import GalleryItem from '@components/galleryitem';

const Huh = styled.h1`
  padding-top: 200px;
`;

const AddtionalWorkContainer = styled.section`
  border: solid 1px purple;
  color: red;
`;

const Title = styled.div`
  font-size: 26px;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  h3 {
    font-weight: var(--font-weight-regular);
    text-transform: none;
    font-size: 20px;
    margin-top: 5px;
  }

  span {
    font-weight: var(--font-weight-regular);
    margin-top: 5px;
  }
`;

const Description = styled.section`
  text-align: center;
  margin-bottom: 50px;
`;

const renderSwitch = item => {
  switch (item.__typename) {
    case 'ContentfulGalleryItem':
      return (
        <GalleryItem
          key={item.id}
          {...item}
          type={item.vimeoId ? 'video' : 'image'}
          width={1000}
        />
      );
    case 'ContentfulGrid':
      return <Grid key={item.id} items={item.items} id={item.id} />;
    case 'ContentfulText':
      return <RichText key={item.id} document={item.richText.json} />;
    case 'ContentfulVideo':
      return (
        <Vimeo
          id={item.vimeoId}
          title={item.title}
          client={item.client.name}
          gallery={true}
        />
      );
    default:
      return null;
  }
};

export default function Project({
  data,
  pageContext: { prev, next },
  location,
}) {
  const hasClient = data.contentfulProject.client.name != 'No Client';
  const originalContent =
    data.contentfulProject.client.name === 'Original Content';

  const projectTitle = () =>
    originalContent ? (
      <h2>
        {data.contentfulProject.title}
        <span> | {data.contentfulProject.client.name}</span>
      </h2>
    ) : (
      <h2>
        {hasClient ? `${data.contentfulProject.client.name}` : null}
        <span>
          {' '}
          {hasClient ? '|' : null} {data.contentfulProject.title}
        </span>
      </h2>
    );

  return (
    <Layout location={location}>
      <Helmet>
        <title>{`${data.contentfulProject.client.name} | ${data.site.siteMetadata.title}`}</title>
        <meta
          name="description"
          content={data.contentfulProject?.description?.description}
        />
      </Helmet>
      <Huh>
        <Wrapper>
          {data.contentfulProject.video && (
            <Vimeo
              id={data.contentfulProject.video.vimeoId}
              title={data.contentfulProject.video.title}
              client={data.contentfulProject.video.client.name}
              gallery={false}
            />
          )}

          <Description>
            <Title>{projectTitle()}</Title>
            {data.contentfulProject.benText && (
              <RichText document={data.contentfulProject.benText.json} />
            )}
          </Description>

          {data.contentfulProject.additionalVideos &&
            data.contentfulProject.additionalVideos.map(item =>
              renderSwitch(item)
            )}
          {/* <Pagination prev={prev} next={next} /> */}
        </Wrapper>
      </Huh>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        url
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      title
      client {
        name
      }
      video {
        vimeoId
        title
        client {
          name
        }
      }
      text {
        json
      }
      benText {
        json
      }
      description {
        description
      }
      additionalVideos {
        ... on ContentfulGalleryItem {
          id
          video {
            vimeoId
          }
          image {
            file {
              url
              contentType
            }
          }
        }
        ... on ContentfulVideo {
          id
          vimeoId
          client {
            name
          }
          title
        }
        ... on ContentfulText {
          id
          richText {
            json
          }
          title
        }
        ... on ContentfulGrid {
          id
          items {
            id
            image {
              file {
                url
                contentType
              }
            }
            video {
              vimeoId
            }
          }
        }
      }
    }
  }
`;
