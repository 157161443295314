import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  a {
    text-decoration: none;
    color: var(--colour-text);
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function GalleryItem({ prev, next }) {
  return (
    <Container>
      {prev && <Link to={`/${prev}`}>Previous</Link>}
      {next && (
        <Link to={`/${next}`} style={{ marginLeft: 'auto' }}>
          Next
        </Link>
      )}
    </Container>
  );
}
